import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../store/index";
import {
  getPlacementDataValue as getPlacementDataValueService,
  getPlacementStringValue as getPlacementStringValueService,
  getPlacementNumberValue as getPlacementNumberValueService,
} from "../services/transformData";
import { Placement, PlacementDataValue } from "../types/Placement";
import { ContentItem } from "../types/UIConfig";

type UsePlacementData = {
  getPlacementDataValue: (
    contentItemOrKey: ContentItem | string
  ) => PlacementDataValue | undefined;
  getPlacementStringValue: (
    contentItemOrKey: ContentItem | string
  ) => string | undefined;
  getPlacementNumberValue: (
    contentItemOrKey: ContentItem | string
  ) => number | undefined;
  getPlacementBoundaries: () => string[] | undefined;
  placement: Placement | undefined;
};
export const usePlacementData = (id: string | undefined): UsePlacementData => {
  const placements = useSelector((state: RootState) => state.placements);
  const placement = React.useMemo(() => {
    if (placements.status !== "finished" || !id) return;
    return placements.byId[id];
  }, [placements, id]);

  const getPlacementDataValue = React.useCallback(
    (contentItemOrKey: ContentItem | string) => {
      if (!placement) return;

      return getPlacementDataValueService(placement, contentItemOrKey);
    },
    [placement]
  );

  const getPlacementStringValue = React.useCallback(
    (contentItemOrKey: ContentItem | string) => {
      if (!placement) return;

      return getPlacementStringValueService(placement, contentItemOrKey);
    },
    [placement]
  );

  const getPlacementNumberValue = React.useCallback(
    (contentItemOrKey: ContentItem | string) => {
      if (!placement) return;

      return getPlacementNumberValueService(placement, contentItemOrKey);
    },
    [placement]
  );

  const getPlacementBoundaries = React.useCallback(() => {
    if (!placement || !placement.boundaries) return;

    return Object.values(placement.boundaries)
      .flatMap((ids) => ids)
      .map((id: string) => id?.trim());
  }, [placement]);

  return {
    getPlacementDataValue,
    getPlacementStringValue,
    getPlacementNumberValue,
    getPlacementBoundaries,
    placement,
  };
};
