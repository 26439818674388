import * as React from "react";
import { CloseButton, Box, Flex, BoxProps, VStack } from "@chakra-ui/react";
import { ProfileImageSection } from "./components/ProfileImageSection";
import { ProfileInfoSection } from "./components/ProfileInfoSection";
import { ProfileTabSection } from "./components/ProfileTabSection";
import { usePanelDisclosure } from "../../hooks/usePanelDislosure";
import { useInlineSizeObserver } from "../../hooks/useInlineSizeObserver";
import { useParams } from "react-router-dom";
import { useConfig } from "../../hooks/useConfig";
import { applyNow } from "../../services/configurableLink";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ApplyNowButton } from "../../components/ApplyNowButton";

type Props = {
  onWidthChange?: (inlineSize: number) => void;
} & BoxProps;
export const PlacementProfile = ({
  onWidthChange,
  ...props
}: Props): React.ReactElement => {
  const { ref } = useInlineSizeObserver(onWidthChange);
  const { placementId } = useParams();
  const { closePlacementProfile } = usePanelDisclosure();
  const config = useConfig();
  const savedIds = useSelector((state: RootState) => state.saved.ids);
  const applyNowLink = config
    ? applyNow(config, placementId ? [placementId] : [])
    : undefined;

  return (
    <Flex
      ref={ref}
      direction="column"
      width="100%"
      height="100%"
      position="relative"
      background="white"
      overflowY="scroll"
      {...props}
    >
      <CloseButton
        onClick={closePlacementProfile}
        position="absolute"
        right={2}
        top={2}
        zIndex={1}
        cursor="pointer"
        color="white"
        backgroundColor="blackAlpha.600"
        borderRadius="50%"
        fontSize="md"
        _hover={{
          opacity: 1,
        }}
        _active={{
          opacity: 1,
        }}
      />
      <ProfileImageSection placementId={placementId} />
      <ProfileInfoSection placementId={placementId} />
      <Box backgroundColor="gray.100" flexGrow={2}>
        <ProfileTabSection placementId={placementId} />
      </Box>
      {applyNowLink && savedIds.length < 1 ? (
        <VStack backgroundColor="gray.100" display="flex" alignItems="flex-end">
          <ApplyNowButton
            applyNowLink={applyNowLink}
            colorScheme="secondary"
          ></ApplyNowButton>
        </VStack>
      ) : (
        <></>
      )}
    </Flex>
  );
};
