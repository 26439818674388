import { IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/popover";
import React from "react";
import { RiQuestionLine } from "react-icons/ri";
import { useIntl } from "react-intl";
import { useColors } from "../../../hooks/useColors";
import { useConfig } from "../../../hooks/useConfig";
import { useStep } from "../hooks/useStep";

const NUM_STEPS = 5;

const colors = {
  1: "#FB8B24",
  2: "#FFC122",
  3: "#F8ED00",
  4: "#B2E325",
  5: "#30D773",
};

export const DemandMeter = (): React.ReactElement | null => {
  const step = useStep();
  const config = useConfig();
  const { secondary } = useColors();
  const intl = useIntl();

  const meter = config?.savedList?.meter;

  if (meter === undefined || step === undefined) return null;

  const color = colors[step];
  const steps = Array.from(Array(NUM_STEPS).keys()).map((i) => i + 1);

  return (
    <Flex height="14px" marginX={8} align="center">
      <Flex
        borderColor={secondary}
        borderWidth="1px"
        width="127px"
        height="100%"
        borderRadius="7px"
        backgroundColor="white"
      >
        {steps.map((stepIndex) => (
          <Flex
            key={stepIndex}
            width={1 / NUM_STEPS}
            align="center"
            _first={{
              borderRadius: "7px 0 0 7px",
            }}
            _last={{
              borderRadius: "0 7px 7px 0",
            }}
            backgroundColor={stepIndex <= step ? color : "white"}
          >
            <Box
              borderRight={stepIndex < NUM_STEPS ? "1px solid" : "none"}
              borderColor={stepIndex < step ? "white" : secondary}
              width="100%"
              height="80%"
            />
          </Flex>
        ))}
      </Flex>
      <Box marginBottom={1} marginLeft={2}>
        <Popover trigger="hover">
          <PopoverTrigger>
            <IconButton
              variant="unstyled"
              aria-label={intl.formatMessage({ id: "DEMAND_METER_ICON_LABEL" })}
              icon={
                <Icon as={RiQuestionLine} color={secondary} fontSize="md" />
              }
              size="xs"
            />
          </PopoverTrigger>
          <PopoverContent
            paddingY={4}
            paddingX={6}
            width={{ base: "calc(100vw - 40px)", lg: "420px" }}
            marginX={{ base: "20px" }}
          >
            <Text
              as="h3"
              fontSize="md"
              fontWeight="semibold"
              color={secondary}
              textAlign="center"
            >
              {meter.hoverableStates?.[step]?.label}
            </Text>
            <Text as="p" marginTop={1} fontSize="sm">
              {meter.hoverableStates?.[step]?.value}
            </Text>
          </PopoverContent>
        </Popover>
      </Box>
    </Flex>
  );
};
