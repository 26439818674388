import React from "react";
import { useSelector } from "react-redux";
import {
  onChangeSavedListAnalytics,
  onSearchAnalytics,
  onFilterChangedAnalytics,
  onChangeResultsAnalytics,
  initializeAnalytics,
} from "../services/analytics";
import { RootState } from "../store";

export const Analytics = (): null => {
  const savedIds = useSelector((state: RootState) => state.saved.ids);
  const query = useSelector(
    (state: RootState) => (state.search as any)?.placements?.text
  );
  const results = useSelector((state: RootState) => state.results);
  const filterSlice = useSelector((state: RootState) => state.filter);
  const config = useSelector((state: RootState) => state.config);

  React.useEffect(() => {
    if (config.state === "finished") {
      initializeAnalytics(config.values.analytics?.googleMeasurementId);
    }
  }, [config]);

  React.useEffect(() => {
    if (results.status === "finished") {
      onChangeResultsAnalytics({ numResults: results.ids.length });
    }
  }, [results]);

  React.useEffect(() => {
    onChangeSavedListAnalytics({ savedIds: savedIds.join(",") });
  }, [savedIds]);

  React.useEffect(() => {
    if (query) {
      onSearchAnalytics({
        query,
      });
    }
  }, [query]);

  React.useEffect(() => {
    const fieldNames = Object.keys(filterSlice.byFieldName);
    if (fieldNames) {
      const selectedFilters = fieldNames
        .map((fieldName) => {
          const filter = filterSlice.byFieldName[fieldName];
          const selected = Array.isArray(filter.selected)
            ? filter.selected
            : [filter.selected];
          return selected.map((value: string | number) => {
            return `${filter.fieldName}.${String(value)}`;
          });
        })
        .flat()
        .join(",");

      onFilterChangedAnalytics({
        selectedFilters,
      });
    }
  }, [filterSlice.byFieldName]);

  return null;
};
