import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonProps, IconButton } from "@chakra-ui/react";
import { RiBookmarkFill, RiBookmarkLine } from "react-icons/ri";

import { RootState } from "../store";
import { useColors } from "../hooks/useColors";
import { addSaved, removeSaved } from "../store/saved";
import { useIntl } from "react-intl";
import { useFocusStyle } from "../hooks/useFocusStyle";
import { useConfig } from "../hooks/useConfig";

type SavePlacementButtonProps = {
  placementId: string | undefined;
} & ButtonProps;

export const SavePlacementButton = ({
  placementId,
  ...buttonProps
}: SavePlacementButtonProps): React.ReactElement => {
  const { ids: savedIds } = useSelector((state: RootState) => state.saved);
  const { primary, secondary } = useColors();
  const intl = useIntl();
  const dispatch = useDispatch();
  const focusStyle = useFocusStyle({ position: "inside" });
  const config = useConfig();

  const isSaved = React.useMemo(() => {
    if (placementId) {
      return savedIds.includes(placementId);
    } else {
      return false;
    }
  }, [savedIds, placementId]);

  const handleSaveToList = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!placementId) return;

    if (isSaved) {
      dispatch(removeSaved(placementId));
    } else {
      dispatch(addSaved(placementId));
    }

    e.preventDefault();
    e.stopPropagation();
  };

  const iconSize = buttonProps.size === "sm" ? 20 : 24;

  return (
    <IconButton
      onClick={handleSaveToList}
      aria-label={intl.formatMessage(
        {
          id: isSaved
            ? "REMOVE_PLACEMENT_BUTTON_LABEL"
            : "SAVE_PLACEMENT_BUTTON_LABEL",
        },
        { school: config?.variableWords.school.singular.label.toLowerCase() }
      )}
      icon={
        isSaved ? (
          <RiBookmarkFill size={iconSize} />
        ) : (
          <RiBookmarkLine size={iconSize} />
        )
      }
      borderRadius={buttonProps.size === "sm" ? "4px" : "0 0 4px 4px"}
      backgroundColor={isSaved ? primary : "gray.100"}
      color={isSaved ? "white" : secondary}
      _hover={{
        backgroundColor: isSaved ? primary : "gray.300",
      }}
      sx={focusStyle}
      {...buttonProps}
    />
  );
};
