import Icon from "@chakra-ui/icon";
import { Flex, Text } from "@chakra-ui/layout";
import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/popover";
import React from "react";
import { RiQuestionLine } from "react-icons/ri";
import { useColors } from "../../../hooks/useColors";
import { useConfig } from "../../../hooks/useConfig";

export const Disclaimer = (): React.ReactElement | null => {
  const config = useConfig();
  const { secondary } = useColors();

  const disclaimer = config?.savedList?.disclaimer;
  if (!disclaimer) return null;

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Flex align="center" marginX={4} cursor="pointer">
          <Text
            color={secondary}
            textDecoration="underline"
            fontSize="xs"
            fontWeight="bold"
            marginX={1}
          >
            {disclaimer.hoverableText}
          </Text>
          <Icon as={RiQuestionLine} />
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        width={{ base: "calc(100vw - 40px)", lg: "420px" }}
        marginX={5}
        padding={5}
      >
        <Text
          as="h3"
          fontSize="md"
          color="secondary"
          fontWeight="bold"
          textAlign="center"
          marginBottom={1}
        >
          {disclaimer.header}
        </Text>
        <Text>{disclaimer.content}</Text>
      </PopoverContent>
    </Popover>
  );
};
