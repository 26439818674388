import * as React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { GoogleMapProps } from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import { RedoSearchButton } from "../components/RedoSearchButton";
import { PlacementId } from "../types/Placement";
import { googleMapLoaded } from "../store/googleMap";
import { GoogleMap } from "./GoogleMap";

type Props = {
  map?: google.maps.Map;
  onLoadMap: (map: google.maps.Map) => void;
  onClick?: () => void;
  onPlacementMarkerClick: (
    placementId: PlacementId,
    position: google.maps.LatLngLiteral
  ) => void;
  profilePanelWidth?: number;
} & BoxProps &
  GoogleMapProps;

export const Map = ({
  map,
  onLoadMap,
  onClick,
  onPlacementMarkerClick,
  profilePanelWidth,
  ...props
}: Props): React.ReactElement => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState<number>(0);
  const [redoSearchShown, setRedoSearchShown] = React.useState<boolean>(false);
  const [boundsInitialized, setBoundsInitialized] =
    React.useState<boolean>(false);
  const dispatch = useDispatch();

  const detectHeight = () => {
    if (!ref?.current) {
      return;
    }
    setHeight(ref.current.getBoundingClientRect().height);
  };

  React.useEffect(() => {
    // need to detect height on 3 different occasions here to make sure it's set properly on all devices/browsers.
    detectHeight();
    window.addEventListener("DOMContentLoaded", detectHeight, true);
    window.addEventListener("resize", detectHeight, true);
    return () => {
      window.removeEventListener("DOMContentLoaded", detectHeight, true);
      window.removeEventListener("resize", detectHeight, true);
    };
  }, []);

  const handleLoadMap = React.useCallback(
    (mapParam: google.maps.Map) => {
      onLoadMap(mapParam);
      dispatch(googleMapLoaded());
    },
    [dispatch, onLoadMap]
  );

  const handleClickRedoSearch = () => {
    setRedoSearchShown(false);
  };

  const handleBoundsChange = () => {
    if (boundsInitialized) {
      setRedoSearchShown(true);
    } else {
      setBoundsInitialized(true);
    }
  };

  return (
    <Box
      {...props}
      ref={ref}
      position="relative"
      height="100%"
      width="100%"
      backgroundColor={"gray.100"}
    >
      {height > 0 && (
        <GoogleMap
          onPlacementMarkerClick={onPlacementMarkerClick}
          onLoad={handleLoadMap}
          onBoundsChange={handleBoundsChange}
          onClick={onClick ? onClick : undefined}
          {...props}
          height={height}
        />
      )}
      {map && (
        <RedoSearchButton
          map={map}
          onClick={handleClickRedoSearch}
          shown={redoSearchShown}
          profilePanelWidth={profilePanelWidth}
        />
      )}
    </Box>
  );
};
