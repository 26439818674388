import Icon from "@chakra-ui/icon";
import { Flex, Spacer, Text } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { Skeleton } from "../../components/Skeleton";
import * as React from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  setSortType,
  SortTypes,
  SORT_TYPE_ALPHABETICAL,
  SORT_TYPE_BOUNDARY,
  SORT_TYPE_DISTANCE,
} from "../../store/sort";
import { useFocusStyle } from "../../hooks/useFocusStyle";
import { useSort } from "../../hooks/useSort";
import { useConfig } from "../../hooks/useConfig";

export const SortPanel = (): React.ReactElement => {
  const dispatch = useDispatch();
  const config = useConfig();
  const intl = useIntl();
  const focusStyle = useFocusStyle({ position: "outside" });

  const results = useSelector((state: RootState) => state.results);
  const sortType = useSelector((state: RootState) => state.sort.type);

  const { sortOptions } = useSort();

  const handleSort = (nextSortType: SortTypes) => () => {
    dispatch(setSortType(nextSortType));
  };

  const labelForSortType = (type: SortTypes): string => {
    if (type === SORT_TYPE_ALPHABETICAL) {
      return intl.formatMessage({ id: "NAME" });
    } else if (type === SORT_TYPE_DISTANCE) {
      return intl.formatMessage({ id: "DISTANCE" });
    } else if (SORT_TYPE_BOUNDARY) {
      return (
        config?.boundary?.sortText ?? intl.formatMessage({ id: "BOUNDARY" })
      );
    }
    return "";
  };

  return (
    <Flex
      align="center"
      backgroundColor={{ base: "gray.100", lg: "white" }}
      fontSize={{ base: "xs", lg: "sm" }}
      paddingX={4}
      height={10}
      borderBottom={{ lg: "1px solid" }}
      borderColor={{ lg: "gray.100" }}
      flexShrink={0}
    >
      {results.status === "finished" ? (
        <Text paddingRight={5}>
          <FormattedMessage
            id="RESULTS_COUNT"
            values={{
              count: results.ids.length,
              schools: config?.variableWords.school.plural.label,
            }}
          />
        </Text>
      ) : (
        <Skeleton
          height="15px"
          width="100px"
          startColor="gray.100"
          endColor="gray.200"
        />
      )}
      <Spacer />
      <Text paddingRight={3} fontSize="xs">
        <FormattedMessage id="SORT_BY" />
      </Text>
      <Menu autoSelect={false}>
        <MenuButton sx={focusStyle}>
          <Flex
            fontSize="sm"
            minWidth="150px"
            border={{ lg: "1px solid" }}
            borderColor={{ lg: "gray.700" }}
            borderRadius={4}
            paddingX={{ lg: 2 }}
            paddingY={{ lg: 1 }}
            align="center"
          >
            {labelForSortType(sortType)}
            <Spacer />
            <Icon as={RiArrowDownSLine} boxSize="20px" color="gray.700" />
          </Flex>
        </MenuButton>
        <MenuList fontSize="sm">
          {sortOptions.map(({ disabled, type }) => (
            <MenuItem
              isDisabled={disabled}
              key={type}
              onClick={handleSort(type)}
            >
              {labelForSortType(type)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};
