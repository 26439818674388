import React from "react";
import * as Redux from "react-redux";
import { RootState } from "../store";
import {
  SortTypes,
  SORT_TYPE_ALPHABETICAL,
  SORT_TYPE_DISTANCE,
  SORT_TYPE_BOUNDARY,
} from "../store/sort";
import { useConfig } from "./useConfig";

export type SortOption = { disabled: boolean; type: SortTypes };
export function useSort() {
  const address = Redux.useSelector((state: RootState) => state.address);
  const config = useConfig();

  return React.useMemo(() => {
    let sortOptions: SortOption[] = [
      { disabled: false, type: SORT_TYPE_ALPHABETICAL },
      { disabled: address.status !== "finished", type: SORT_TYPE_DISTANCE },
    ];

    if (config?.boundary?.sort === true) {
      sortOptions.push({
        disabled: address.status !== "finished",
        type: SORT_TYPE_BOUNDARY,
      });
    }

    return {
      sortOptions,
    };
  }, [address, config?.boundary?.sort]);
}
