import { useDisclosure } from "@chakra-ui/react";
import React from "react";
import {
  ButtonProps,
  ConfirmationDialog,
  ConfirmationDialogProps,
} from "./ConfirmationDialog";

export type ConfirmProps = {
  header?: React.ReactNode;
  body?: React.ReactNode;
  confirmButton?: ButtonProps;
  cancelButton?: ButtonProps;
};

export function useConfirmationDialog({
  body,
  header,
  ...props
}: Omit<ConfirmationDialogProps, "onCancel" | "onConfirm" | "isOpen">) {
  const [dynamicBody, setBody] = React.useState<React.ReactNode>(body);
  const [dynamicHeader, setHeader] = React.useState<React.ReactNode>(header);
  const [dynamicCancelButton, setDynamicCancelButton] = React.useState<
    ButtonProps | undefined
  >(undefined);
  const [dynamicConfirmButton, setDynamicConfirmButton] = React.useState<
    ButtonProps | undefined
  >(undefined);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const promiseRef = React.useRef<
    { resolve: (value: boolean) => void } | undefined
  >();

  const confirm = async (confirmProps?: ConfirmProps): Promise<boolean> => {
    if (confirmProps?.header) {
      setHeader(confirmProps.header);
    }
    if (confirmProps?.body) {
      setBody(confirmProps.body);
    }
    if (confirmProps?.confirmButton) {
      setDynamicCancelButton(confirmProps.cancelButton);
    }
    if (confirmProps?.cancelButton) {
      setDynamicConfirmButton(confirmProps.confirmButton);
    }

    onOpen();
    return new Promise((resolve, reject) => {
      promiseRef.current = { resolve };
    });
  };

  const onCancel = () => {
    onClose();
    if (promiseRef.current) promiseRef.current.resolve(false);
  };

  const onConfirm = () => {
    onClose();
    if (promiseRef.current) promiseRef.current.resolve(true);
  };

  return {
    confirm,
    confirmationDialog: (
      <ConfirmationDialog
        isOpen={isOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
        header={dynamicHeader}
        body={dynamicBody}
        {...props}
        confirmButton={
          dynamicConfirmButton ? dynamicConfirmButton : props.confirmButton
        }
        cancelButton={
          dynamicCancelButton ? dynamicCancelButton : props.cancelButton
        }
      />
    ),
    setBody,
    setHeader,
  };
}
