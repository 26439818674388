import * as React from "react";
import queryString from "query-string";
import { Autocomplete } from "@react-google-maps/api";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { InputText } from "../components/InputText";
import { RootState } from "../store";
import { useIntl } from "react-intl";
import { onSelectAddressAnalytics } from "../services/analytics";
import { useFocusStyle } from "../hooks/useFocusStyle";
import { useConfig } from "../hooks/useConfig";

interface Props {
  onFocus?: () => void;
  onBlur?: () => void;
  map?: google.maps.Map;
}

export const AddressSearchBar = (props: Props): React.ReactElement | null => {
  const { onFocus, onBlur, map } = props;
  const focusStyle = useFocusStyle({ position: "offset", color: "primary" });

  const [autocomplete, setAutocomplete] =
    React.useState<google.maps.places.Autocomplete>();
  const address = useSelector((state: RootState) => state.address);
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const googleMap = useSelector((state: RootState) => state.googleMap);
  const config = useConfig();

  const handleLoad = (ac: google.maps.places.Autocomplete) => {
    setAutocomplete(ac);
  };

  const handlePlaceChanged = () => {
    if (!autocomplete) return;

    const place = autocomplete.getPlace();

    if (
      !place.place_id ||
      !place.formatted_address ||
      !place.geometry?.location
    )
      return;

    const params = queryString.parse(location.search);
    params["place_id"] = place.place_id;
    navigate(`${location.pathname}?${queryString.stringify(params)}`);

    onSelectAddressAnalytics();
  };

  if (address.status === "loading") return null;
  if (googleMap.status === "loading") return null;

  return (
    <Autocomplete
      onLoad={handleLoad}
      onPlaceChanged={handlePlaceChanged}
      bounds={map?.getBounds()}
    >
      <InputText
        label="Address"
        defaultValue={
          address.status === "finished" ? address.values.formattedText : ""
        }
        placeholder={intl.formatMessage(
          {
            id: "ADDRESS_PLACEHOLDER",
          },
          { schools: config?.variableWords.school.plural.label.toLowerCase() }
        )}
        onFocus={onFocus}
        onBlur={onBlur}
        backgroundColor="white"
        sx={focusStyle}
      />
    </Autocomplete>
  );
};
