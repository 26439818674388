import * as s from "../services/sessionStorage";
import { FIRST_SAVED_SCHOOL_CONTEXT } from "../types/sessionStorageKeys";

export type Context = {
  confirmationDisplayed: boolean;
};

export function getContext(): Context | null {
  return s.getSessionStorageRecord<Context>(FIRST_SAVED_SCHOOL_CONTEXT);
}

export function saveContext(ctx: Context | null): void {
  return s.saveSessionStorageRecord<Context>(FIRST_SAVED_SCHOOL_CONTEXT, ctx);
}
