import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Address {
  googlePlaceId: string;
  formattedText: string;
  lat: number;
  lng: number;
}

type AddressSliceState =
  | { status: "initial" }
  | { status: "loading" }
  | {
      status: "finished";
      values: Address;
    };

const initialState: AddressSliceState = {
  status: "initial",
} as AddressSliceState;

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setAddressLoading(state) {
      state.status = "loading";
    },
    setAddress(state, action: PayloadAction<Address>) {
      state.status = "finished";
      const { googlePlaceId, formattedText, lat, lng } = action.payload;
      if (state.status === "finished") {
        state.values = {
          googlePlaceId,
          formattedText,
          lat,
          lng,
        };
      }
    },
  },
});

export const { setAddress, setAddressLoading } = addressSlice.actions;
export default addressSlice.reducer;
