import * as React from "react";
import { useDisclosure, Flex, Spacer, IconButton } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DragUpdate,
  DragStart,
  ResponderProvided,
} from "react-beautiful-dnd";
import { RootState } from "../../store";
import { SavedPlacementCard } from "../../components/SavedPlacementCard";
import { setSaved } from "../../store/saved";
import { SavedPlacementsFooter } from "./components/SavedPlacementsFooter";
import { SmsForm } from "./components/SmsForm";
import { AnimatePresence } from "framer-motion";
import { EmailForm } from "./components/EmailForm";
import { EmptyList } from "../../components/EmptyList";
import { FormattedMessage, useIntl } from "react-intl";
import { Disclaimer } from "./components/Disclaimer";
import { useConfig } from "../../hooks/useConfig";
import { DemandMeter } from "./components/DemandMeter";
import { useRemoveNonexistentPlacementIds } from "./hooks/useRemoveNonexistentPlacementIds";
import { RiCloseLine } from "react-icons/ri";

interface Props {
  onClose: () => void;
}
export const SavedPlacements = ({
  onClose,
}: Props): React.ReactElement | null => {
  const savedIds = useSelector((state: RootState) => state.saved.ids);
  const [draggedIndex, setDraggedIndex] = React.useState<number | undefined>();
  const ref = React.useRef<HTMLDivElement>(null);
  const config = useConfig();
  const dispatch = useDispatch();
  const intl = useIntl();
  useRemoveNonexistentPlacementIds(savedIds);

  const {
    isOpen: isSmsOpen,
    onToggle: onSmsToggle,
    onClose: onSmsClose,
  } = useDisclosure();
  const {
    isOpen: isEmailOpen,
    onToggle: onEmailToggle,
    onClose: onEmailClose,
  } = useDisclosure();

  React.useEffect(() => {
    if (savedIds.length === 0) {
      onSmsClose();
      onEmailClose();
    }
  }, [onEmailClose, onSmsClose, savedIds]);

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (result.destination) {
      let nextSavedIds = [...savedIds];
      nextSavedIds.splice(result.source.index, 1);
      nextSavedIds.splice(result.destination.index, 0, result.draggableId);
      dispatch(setSaved(nextSavedIds));
    }
    setDraggedIndex(undefined);
  };

  const handleDragUpdate = (initial: DragUpdate | DragStart) => {
    setDraggedIndex(initial.source.index);
  };

  return (
    <Flex
      ref={ref}
      height="100%"
      width="100%"
      direction="column"
      background="gray.100"
    >
      <Flex
        align="center"
        flexGrow={0}
        borderBottom="1px solid"
        borderBottomColor="gray.500"
        justifyContent="flex-end"
        position="relative"
        paddingY={1}
      >
        {config?.savedList?.meter ? <DemandMeter /> : <Disclaimer />}
        <Spacer />
        <IconButton
          aria-label={intl.formatMessage(
            { id: "CLOSE_SAVED_SCHOOLS_LABEL" },
            { schools: config?.variableWords.school.plural.label }
          )}
          icon={<RiCloseLine size={20} />}
          right={1}
          onClick={onClose}
          size="sm"
        />
      </Flex>
      {savedIds.length === 0 ? (
        <EmptyList
          header={
            <FormattedMessage
              id="SAVED_LIST_EMPTY_HEADER"
              values={{
                schools:
                  config?.variableWords.school.plural.label.toLowerCase(),
              }}
            />
          }
          description={
            <FormattedMessage
              id="SAVED_LIST_EMPTY_DESCRIPTION"
              values={{
                schools:
                  config?.variableWords.school.plural.label.toLowerCase(),
              }}
            />
          }
        />
      ) : (
        <DragDropContext
          onDragEnd={handleDragEnd}
          onDragStart={handleDragUpdate}
          onDragUpdate={handleDragUpdate}
        >
          <Droppable droppableId="savedList">
            {(provided) => (
              <Flex
                ref={provided.innerRef}
                paddingY={3}
                direction="column"
                overflowY="auto"
                flexGrow={1}
                overflow="auto"
                {...provided.droppableProps}
              >
                {savedIds.map((id: string, index: number) => (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(provided, snapshot) => (
                      <SavedPlacementCard
                        id={id}
                        index={index}
                        provided={provided}
                        snapshot={snapshot}
                        draggedIndex={draggedIndex}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <AnimatePresence initial={false}>
        {isSmsOpen && <SmsForm key="smsForm" />}
        {isEmailOpen && <EmailForm key="emailForm" />}
      </AnimatePresence>
      <SavedPlacementsFooter
        onSmsToggle={() => {
          onEmailClose();
          onSmsToggle();
        }}
        onEmailToggle={() => {
          onSmsClose();
          onEmailToggle();
        }}
        flexGrow={0}
      />
    </Flex>
  );
};
