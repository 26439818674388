import axios from "axios";

export type Organization = {
  path: string;
  title: string;
};

export const DEFAULT_ORGANIZATION_PATH = "demo";

export const fetchOrganization = async (
  hostname: string
): Promise<Organization> => {
  /*
  // put this back when we need to run locally
  return {
    path: "philasd",
    title: "@@@ TESTING",
  };
  */
  const URL = process.env.REACT_APP_PIPELINE_ENDPOINT;
  if (!URL) return Promise.reject();

  const path = `${URL}/lookup`;
  return await axios.post(path, { hostname: hostname }).then(({ data }) => {
    return data;
  });
};
