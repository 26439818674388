import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PlacementId = string;
type SavedSliceType = {
  ids: PlacementId[];
};
const initialState: SavedSliceType = {
  ids: [],
} as SavedSliceType;

const savedSlice = createSlice({
  name: "saved",
  initialState,
  reducers: {
    setSaved: (state, action: PayloadAction<PlacementId[]>) => {
      state.ids = action.payload;
    },
    addSaved: (state, action: PayloadAction<PlacementId>) => {
      state.ids.push(action.payload);
    },
    removeSaved: (state, action: PayloadAction<PlacementId>) => {
      state.ids = state.ids.filter((id) => id !== action.payload);
    },
  },
});

export const { setSaved, addSaved, removeSaved } = savedSlice.actions;
export default savedSlice.reducer;
