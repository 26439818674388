import React from "react";
import { Flex, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";

import { useColors } from "../hooks/useColors";
import emptySchoolImg from "../images/empty-school.png";

interface EmptyListProps {
  header: React.ReactNode;
  description: React.ReactNode;
}

export const EmptyList = ({
  header,
  description,
}: EmptyListProps): React.ReactElement => {
  const { secondary } = useColors();

  return (
    <Flex
      direction="column"
      align="center"
      padding={12}
      flexGrow={1}
      overflowY="auto"
    >
      <Text color={secondary} fontWeight="bold" fontSize="lg">
        {header}
      </Text>
      <Text fontSize="md" marginY={6}>
        {description}
      </Text>
      <Image
        width={240}
        src={emptySchoolImg}
        marginTop="56px"
        filter="grayscale(100%)"
        opacity={0.5}
      />
    </Flex>
  );
};
