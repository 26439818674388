import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const SORT_TYPE_ALPHABETICAL = "alphabetical";
export const SORT_TYPE_DISTANCE = "distance";
export const SORT_TYPE_BOUNDARY = "boundary";

export type SortTypes =
  | typeof SORT_TYPE_ALPHABETICAL
  | typeof SORT_TYPE_DISTANCE
  | typeof SORT_TYPE_BOUNDARY;

type SortSliceState = {
  type: SortTypes;
};

const initialState: SortSliceState = {
  type: SORT_TYPE_ALPHABETICAL,
};

const sortSlice = createSlice({
  name: "sort",
  initialState,
  reducers: {
    setSortType(state, action: PayloadAction<SortTypes>) {
      const { payload } = action;
      state.type = payload;
    },
  },
});

const { reducer } = sortSlice;

export const { setSortType } = sortSlice.actions;

export default reducer;
