import React from "react";
import { CustomMarker } from "./CustomMarker";
import { usePlacementData } from "../hooks/usePlacementData";
import { placementPath } from "../services/urlBuilder";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { FormattedMessage, useIntl } from "react-intl";
import { FormattedDistanceToPlacement } from "./FormattedDistanceToPlacement";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Placement, PlacementId } from "../types/Placement";
import { ContentItem } from "../types/UIConfig";
import { Cluster } from "../services/map";
import { Clusterer } from "@react-google-maps/marker-clusterer";

export type Props = {
  handleClick: (
    placementId: PlacementId,
    position: google.maps.LatLngLiteral
  ) => void;
  cluster: Cluster<Placement>;
  disableHover?: boolean;
  clusterer: Clusterer;
};

export const PlacementMarker = ({
  handleClick,
  cluster,
  disableHover = false,
  ...props
}: Props): React.ReactElement | null => {
  const placementId = cluster.coordinates[0]?.id;
  const { placement, getPlacementStringValue } = usePlacementData(placementId);
  const distance = useSelector((state: RootState) => state.distance);
  const highlightedPlacementId = useSelector(
    (state: RootState) => state.highlightedPlacement.highlightedPlacementId
  );
  const config = useSelector((state: RootState) => state.config);
  const distanceToPlacement = distance?.distanceToPlacementById?.[placementId];
  const intl = useIntl();

  if (config.state !== "finished") return null;

  const lat = placement?.lat;
  const lng = placement?.lng;
  const name = placement?.name;

  if (lat === undefined || lng === undefined) return null;

  const isHighlighted =
    !!highlightedPlacementId && highlightedPlacementId === placementId;

  return (
    <CustomMarker
      {...props}
      markerType="placement"
      key={placementId}
      position={{ lat, lng }}
      cluster={cluster}
      url={placementPath(placementId)}
      onClick={() => handleClick(placementId, { lat, lng })}
      isHighlighted={isHighlighted}
      popoverContent={
        disableHover ? undefined : (
          <Box fontSize="sm" lineHeight="24px">
            <Text fontWeight="semibold">{placement?.name}</Text>
            <Flex flexWrap="wrap">
              <Text marginRight={1}>
                {config.values.map.popover.highlighted.map(
                  (item: ContentItem, index: number) => {
                    if (index === 0) {
                      return (
                        <Box as="span" key={index} fontWeight="semibold">
                          {getPlacementStringValue(item)}
                        </Box>
                      );
                    } else {
                      return (
                        <Box as="span" key={index}>
                          {` | ${getPlacementStringValue(item)}`}
                        </Box>
                      );
                    }
                  }
                )}
              </Text>
              {distanceToPlacement && (
                <Text>
                  <Box as="span" marginRight={1}>
                    <FormattedMessage id="DISTANCE" />:
                  </Box>
                  <FormattedDistanceToPlacement id={placementId} />
                </Text>
              )}
            </Flex>
          </Box>
        )
      }
      label={name || intl.formatMessage({ id: "DEFAULT_MARKER_LABEL" })}
    />
  );
};
